import React from 'react'

// Components
import { WebIcon, AppIcon, BrandIcon, PrintIcon, EbookIcon } from '../src/components/Services/Icons'

const ServiceData = [
  {
    icon: <WebIcon />,
    name: `Web Design & Development`,
    href: `web`,
    focal: `Our Franklin-based web design & development agency can take care of all your web
    design & development needs.`,
    details: [
      `The Web Mobsters approach when it comes to web design and development is quite simple:
      make it responsive, make it modern and make it an engaging user experience. We provide
      you with a bespoke service from the initial concept to the final result, ensuring your
      virtual visiting card makes you stand out from your competitors and converts your
      visitors into customers. Our web design & development services include but are not
      limited to:`,
    ],
    bullets: [
      `Responsive and cohesive design across all platforms – desktop or mobile`,
      `CMS implementation – so that you gain full customization control over your website`,
      `SEO optimization to gain Google’s favors as soon as your website is up and running`,
      `Security services to ensure your website is safe at every level`,
      `Hosting services – we provide your website with a reliable server to live on`,
      `24/7 assistance – if anything goes wrong with your website, our experts are there to fix it`,
    ],
  },
  {
    icon: <AppIcon />,
    href: `app`,
    name: `App Design & Development`,
    focal: `Our Franklin-based app design & development agency can handle all your app design
    & development needs.`,
    details: [
      `Your target audience probably spends most of their day with their phones glued to
    their hands. We can help you make their time worthwhile by creating a robust,
    scalable, and smooth iOS/Android/Cross-platform app that looks and feels incredible.
    From planning to design, development, testing, fixing bugs, payment gateway
    integration, and a wide array of cool features – our Web Mobsters crew has got your
    back. Our app design & development services include but are not limited to:`,
      `Responsive and secure app design and development for Android/iOS/Cross-platform
    apps;`,
    ],
    bullets: [
      `Support and maintenance`,
      `Integration of a wide array of features`,
      `Web Services & APIs`,
      `App updates`,
    ],
  },
  {
    icon: <BrandIcon />,
    name: `Brand Development`,
    href: `brand`,
    focal: `We provide clients in Franklin with bespoke brand development services.`,
    details: [
      `In order to outshine your competitors, your business needs a storytelling-driven
    visual identity that is unique, compelling, and contributes to your positioning on the
    market. In every brand development project, we aim for a branding strategy that
    captures the client’s brand values and core offerings, making it memorable and
    outstanding. From the logo to the brand color palette, we build a brand identity
    representing who you are, what you do, and what you stand for.`,
      `Our Brand Development services include but are not limited to:`,
    ],
    bullets: [
      `Logo design – we create a unique logo that enables your customers to recognize your
    brand easily`,
      `Strategy-driven brand identity development – we work closely with you to build an
    identity that conveys your brand’s voice and messaging in every detail – from the
    color palette to the graphic elements and typography`,
      `Naming & messaging – our wordsmiths help you create a suitable name and tagline
      for your brand`,
    ],
  },
  {
    icon: <PrintIcon />,
    name: `Print & Packaging`,
    href: `print`,
    focal: `We offer high-quality print & packaging services for clients in Franklin,
    Tennessee.`,
    details: [
      `Prints and packaging that align with your brand identity can boost your brand
    awareness. Our friendly team is here to create creative and cool designs that
    instantly catch your customers’ attention and make you memorable. From flyers,
    leaflets, brochures, business cards, labels, product boxes to posters, wobblers,
    booklets, and greeting cards – we’ve got all your print & packaging needs covered.
    We use advanced tools and equipment to create high-quality materials in vibrant colors
    that stay true to your branding.`,
    ],
    bullets: [
      `Packaging design`,
      `Design and printing for a wide array of items – flyers, leaflets, brochures etc`,
    ],
  },
  {
    icon: <EbookIcon />,
    name: `eBook Development`,
    href: `ebook`,
    focal: `We provide clients in Franklin, Tennessee with bespoke eBook development services`,
    details: [
      `In the era of eco-friendly Kindle and eReaders, eBooks still have to make a good
    visual impression. Web Mobsters provides you with a complete range of eBook
    development-related services, from conversions and customization and typesetting. We
    can make your eBook friendly for various platforms and devices.`,
      `Our eBook development services include, but are not limited to:`,
    ],
    bullets: [
      `Conversion of any document to eBook format (Mobi, PDF, ePUB)`,
      `Cover design using artwork, stock imagery, illustrations`,
      `eBook layout design`,
      `eBook formatting`,
      `eBook optimization for all platforms and devices – Kindle, Nook, Android phones and
      tablets, iOS phones and tablets and many more`,
    ],
  },
]

export default ServiceData
