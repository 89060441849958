import React from 'react'
import styled from 'styled-components'

// Master Components
import { BtnOutline } from '../../../theme/Buttons'

const BtnWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  display: flex;
`

const ServiceButtons = () => {
  return (
    <>
      <BtnWrapper>
        <BtnOutline href="portfolio" title="Portfolio" />
        <BtnOutline href="contact" title="Contact" />
      </BtnWrapper>
    </>
  )
}

export default ServiceButtons
