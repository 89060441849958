import React from 'react'
import styled from 'styled-components'

// Master Components
import { H2, Pb } from '../../theme/Typography'
import { Bhr } from '../../theme/GlobalStyles'

// Components
import Layout from '../components/Layout'
import ServiceButtons from '../components/Services/ServiceButtons'

// Data
import ServiceData from '../../data/services'

const ServiceWrapper = styled.div`
  margin-top: -${({ theme }) => theme.spacings.xLarge}; ;
`

const ServiceIcon = styled.div`
  svg {
    height: ${({ theme }) => theme.spacings.xxLarge};
  }
`

const ServiceHead = styled(H2)`
  color: ${({ theme }) => theme.colors.charleston};
  margin-bottom: ${({ theme }) => theme.spacings.small};
  letter-spacing: 1px;
`

const ServiceDetails = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.small};
`

const Focal = styled(Pb)`
  font-style: italic;
  font-size: ${({ theme }) => theme.mobileSizes.h5};
  color: ${({ theme }) => theme.colors.pink};
`

const ListGroup = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`

const ListItem = styled.li`
  margin-left: ${({ theme }) => theme.spacings.medium};
  text-indent: -${({ theme }) => theme.spacings.small};

  &:before {
    content: '•';
    color: ${({ theme }) => theme.colors.violet};
    margin-right: ${({ theme }) => theme.spacings.small};
  }
`

const Anchor = styled.div`
  display: block;
  padding-bottom: ${({ theme }) => theme.spacings.xLarge};
`

const Services = () => {
  return (
    <>
      <Layout title="Services" subTitle="What we can do for you">
        <ServiceWrapper>
          {ServiceData.map((service, key) => {
            return (
              <React.Fragment key={key}>
                {key !== 0 ? <Bhr id={service.href} /> : <Anchor id={service.href} />}
                <ServiceIcon>{service.icon}</ServiceIcon>
                <ServiceHead>{service.name}</ServiceHead>
                <Focal>{service.focal}</Focal>
                <ServiceDetails>
                  {service.details.map((detail, key) => {
                    return (
                      <React.Fragment key={key}>
                        <Pb>{detail}</Pb>
                      </React.Fragment>
                    )
                  })}
                  <ListGroup>
                    {service.bullets.map((bullet, key) => {
                      return (
                        <React.Fragment key={key}>
                          <ListItem>{bullet}</ListItem>
                        </React.Fragment>
                      )
                    })}
                  </ListGroup>
                  <ServiceButtons />
                </ServiceDetails>
              </React.Fragment>
            )
          })}
        </ServiceWrapper>
      </Layout>
    </>
  )
}

export default Services
